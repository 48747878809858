<section class="input-container">
	<div class="label-input">
		<label *ngIf="label" [for]="id + '-input'">
			<span>{{label}}</span>
			<span *ngIf="isRequired" class="required-star"> *</span>
		</label>
		<h3 class="m-0">
			<input [id]="id + '-input'"
			       [ngStyle]="{'height': !label ? 'inherit' : 'revert-layer'}"
			       [type]="type"
			       [disabled]="disabled"
			       [min]="min"
			       [max]="max"
			       [maxlength]="maxLength"
			       [placeholder]="placeholder"
			       [(ngModel)]="value"
			       (blur)="onTouch(); isFocused = false"
			       (focus)="isFocused = true">
		</h3>
	</div>
	<div class="icon" *ngIf="iconUri">
		<img alt="input icon" [src]="iconUri" (click)="iconClicked.emit()">
	</div>
</section>
