import {Component, EventEmitter, Host, HostBinding, Input, OnInit, Output} from '@angular/core';
import {
	AbstractControl, ControlContainer,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR, NgControl,
	ValidationErrors,
	Validator,
	Validators
} from '@angular/forms';

@Component({
	selector: 'aga-text-field[id]',
	templateUrl: './text-field.component.html',
	styleUrls: ['./text-field.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextFieldComponent,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: TextFieldComponent,
			multi: true
		}
	],
})
export class TextFieldComponent implements OnInit, ControlValueAccessor, Validator {
	@Input() type: 'password' | 'text' | 'number' | 'email' | string = 'text';
	@Input() id: string;
	@Input() label: string;
	@Input() iconUri: string;
	@Input() min: number;
	@Input() max: number;
	@Input() maxLength: number;
	@Input() placeholder: string | number = '';
	@Output() iconClicked: EventEmitter<any> = new EventEmitter<any>();
	@HostBinding('class.disabled')@Input() disabled: boolean;
	@HostBinding('class.focused') isFocused: boolean;
	@HostBinding('class.is-empty') isEmpty = true;
	isRequired: boolean;

	onChange: any = () => {
	};
	onTouch: any = () => {
	};
	isProgrammaticChange: boolean;
	_value: string | number;

	set value(value: string | number) {
		this._value = this.type === 'number' ? +value : value;
		this.isEmpty = value === undefined || value === null || value === '';
		if (!this.isProgrammaticChange) {
			this.onChange(this._value);
			this.onTouch(this._value);
		} else {
			this.isProgrammaticChange = false;
		}
	}

	get value() {
		return this._value;
	}

	ngOnInit(): void {
		if (!this.id) {
			throw new Error(`Text field attribute 'id' is required`);
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(value: string | number): void {
		this.isProgrammaticChange = true;
		this.value = value;
	}

	validate(control: AbstractControl): ValidationErrors | null {
		this.isRequired = control.hasValidator(Validators.required) || control.errors?.required;
		return null;
	}
}
